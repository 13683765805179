<template>
  <div class="">
    <!-- v-if="getNavbarData.userType == 'shopify'" -->
    <button
                      class="table-action-button bg-white"
                      @click.stop="triggerImportProducts" style="font-size:12px">
                      <i class="fa-solid mr-2 fa-file-import"></i> Import
                    </button>
    <!-- Shopify Modal -->
    <ModalVue
      ref="ImportModal"
      id="ImportModal"
      title="<span class='mx-2'>Import Products</span>"
      size="lg">
      <div class="mx-2">
        <div class="">
            <p>Download a <a :href="csvFileDownload" download="product.csv">sample CSV template</a> to see an example of the format required.</p>
        </div>
        <div class=""><b-alert variant="danger" :show="error?true:false">{{ error }}</b-alert></div>
        <div class="py-2">

          <b-progress :value="uploadProgress" max="100" show-progress animated :label="`${uploadProgress}%`" v-show="isUploadingFile"></b-progress>
        </div>
        <div class="content-drag-drop">
              <div v-if="fileExtension" class="block-add-products border d-flex justify-content-center align-items-center" style="font-size: 40px;">
      <i :class="getFileIconClass" class="px-2" style="font-size: 50px;"></i> <!-- Display the icon -->
      <span>{{ fileExtension }}</span>
    </div>

            <div v-else class="block-add-products  d-flex justify-content-center align-items-center" @click="$refs.openFile.click()"
            @dragover.prevent
      @dragenter="isDragging=true"
      @dragleave="isDragging = false"
      @drop.prevent="handleFileDrop"
            :style="{border: isDragging?'2px dashed #bfbfc0':'1px solid #bfbfc0'}"
            >
            <!-- @dragenter="isDragging=true"
            @dragleave="isDragging=false" -->
                <div  v-if="isDragging" class="h5" style="text-align:center"> <i class="fa fa-upload py-2"  aria-hidden="true"></i> <div>Drop Your File Here</div></div>
                <div v-else class="add-btn text-center">
                   Add File
                </div>
                <input type="file" name="" id="" class="d-none" @change="handleFileUpload" accept=".csv, application/vnd.ms-excel" ref="openFile">
            </div>
        </div>
        <div class="my-3">
              <b-form-checkbox
                      id="SelectAllImage"
                     class="checkbox"
                      name="SelectAll"
                      v-model="isOverride"
                    >
                      Overwrite any current products that have the same handle. Existing values will be used for any missing columns
                    </b-form-checkbox>
                    <div class="float-right" v-if="fileExtension">
                      <b-button variant="link" @click="resetFile()" :disabled="isUploadingFile">Select other file</b-button>
                        <Button @click="uploadFile" :is-loading="isUploadingFile">Upload File</Button>
                    </div>
        </div>
      </div>
    </ModalVue>
  </div>
</template>
<script>

import XLSX from 'xlsx';
import axios from 'axios';
import { mapGetters } from 'vuex';
  export default {
    components: {
      ModalVue: () =>
      import(
        /* webpackChunkName: "ModalVue" */ "../../Layout/Modal.vue"
      ),
      Button: () =>
      import(
        /* webpackChunkName: "Button" */ "../../Layout/Button.vue"
      ),
    },
    data() {
      return {
        isUploadingFile:false,
        error:null,
        csvData:[],
        isDragging:false,
        requiredFields:['title'],
        fileExtension:'',
        file:null,
        uploadProgress:0,
        isOverride:true,
        csvHeader:[],
         optionalHeaders: [
        'price',
        'image',
        'detail_link',
        'product_type',
        'description',
        'status',
        'compare_at_price',
        'created_at',
        'external_id',
        'tags',
        'sku',
        'vendor',
        'quantity',
        'bullet_description'
      ]
      };
    },
    computed:{
      ...mapGetters(["getNavbarData","getUserLimitations"]),
    getUserType() {
      return this.getNavbarData.userType;
    },
    IsLimitProductValid() {
      const allLimitations = [...this.getUserLimitations];
      const limitation = allLimitations.filter(
        (limit) => limit.text == "Products"
      );
      if (limitation) {
        return limitation[0].planValue != 0
          ? limitation[0].userValue <= limitation[0].planValue
          : true;
      }
      return false;
    },
      csvFileDownload(){
       // The CSV data
const csvData = `title,price,image,detail_link,product_type,description,status,compare_at_price,created_at,external_id,tags,sku,vendor,quantity,bullet_description
Product 1,19.99,https://example.com/product1.jpg,https://example.com/product1-link,Category A,Description for Product 1,1,24.99,,123456,TagA,SKU123,Vendor A,100,bullet1
Product 2,29.99,https://example.com/product2.jpg,https://example.com/product2-link,Category B,Description for Product 2,1,34.99,,789012,TagB,SKU456,Vendor B,200,bullet2
Product 3,39.99,https://example.com/product3.jpg,https://example.com/product3-link,Category C,Description for Product 3,1,44.99,,345678,TagC,SKU789,Vendor C,50,bullet3
Product 4,49.99,https://example.com/product4.jpg,https://example.com/product4-link,Category A,Description for Product 4,1,54.99,,901234,TagD,SKU101,Vendor D,75,bullet4
Product 5,59.99,https://example.com/product5.jpg,https://example.com/product5-link,Category D,Description for Product 5,1,64.99,,567890,TagE,SKU202,Vendor E,150,bullet4`;

// Convert the CSV data to a Blob
const blob = new Blob([csvData], { type: 'text/csv' });

// Create a URL for the Blob
return  URL.createObjectURL(blob);
      },
          getFileIconClass() {
      const iconMap = {
        csv: 'fa-solid fa-file-csv',   // FontAwesome class for PDF files
        xls: 'fa-solid fa-sheet-plastic',  // FontAwesome class for Word files
      };
      return iconMap[this.fileExtension] || iconMap['default'];
    },
    },
    methods: {
      resetFile(){
        this.csvData=[];
        this.uploadProgress=0;
        this.fileExtension='';
        this.file=null;
        this.isUploadingFile=false;
      },
        toast(text){
            this.$toasted.show(text, {
                    theme: "toasted-primary",
                    position: "bottom-center",
                    duration : 2000,

                });
        },
      openImportProductModal() {
        this.$refs.ImportModal.open();
      },
       closeShopifyProductModal() {
        this.$refs.ImportModal.close();
      },
     handleFileDrop(event) {
          this.isDragging = false;
          // Handle the dropped file here
          const files = event.dataTransfer.files;
          if (files.length > 0) {
            this.handleFileUpload(files[0],true)
            // Do something with the dropped file(s)
            
          }
        },
      async  handleFileUpload(event,fromFromDrag=false) {
          this.error=null;
          let file= null
          if(fromFromDrag)
         file=event
          else
        file = event.target.files[0];
      this.file=file;
      if (file) {
        const allowedFileTypes = ['.csv', 'application/vnd.ms-excel'];
        if (!allowedFileTypes.includes(file.type) && !file.name.toLowerCase().endsWith('.csv') && !file.name.toLowerCase().endsWith('.xls')) {
          this.error = 'Please select a CSV/XLS.';
          this.toast('File must be CSV/XLS.')
          return;
        }
        const fileName = file.name;
        this.fileExtension = fileName.split('.').pop();
         
        const reader = new FileReader();
         reader.onload = async (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook =  XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const csvData = await XLSX.utils.sheet_to_json( worksheet, { header: 1 });
          this.csvData = csvData;
          if (csvData.length === 0) {
            this.error = 'File is empty.';
          } else {
            const headerRow = csvData[0];
            if (!headerRow.includes('title')) {
              this.error = 'File does not contain the "title" column.';
            } else {
              const additionalHeaders = headerRow.filter(header => !['title', ...this.optionalHeaders].includes(header));
              if (additionalHeaders.length > 0) {
                this.error = 'File contains extra column(s) [' +additionalHeaders+ '].' ;
              } else {
                this.csvHeader = Object.keys(headerRow);
              }
            }
        }
        if(this.error!=null)
        this.resetFile();
      }
       reader.readAsArrayBuffer(file);
      }
    },
       async uploadFile() {
      this.isUploadingFile=true;
      if (!this.file) {
        return;
      }
      const formData = new FormData();
      formData.append('products_csv_file', this.file);
      formData.append('overwrite_products',this.isOverride)

     await axios.post('/products/importbycsv', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          },
        })
        .then((response) => {
          if(response.status)
          {
            this.toast('Products has been imported successfully.');
          this.closeShopifyProductModal();
          this.$emit('loadSavedProducts')
          }
        })
        .catch((error) => {
          this.error=error.response.data.message
          console.log(error.response.data.message)
          // Handle errors
        });
        this.isUploadingFile=false;
        this.uploadProgress=0;
    },
    triggerImportProducts(){
      if (this.IsLimitProductValid) {
        this.openImportProductModal()
      } else {
        this.$emit('openPlanModal');
      }
    },
  }
  };
</script>
<style scoped>
.add-btn{
    background: #fdfdfd;
    border-radius: 12px;
    color: #303030;
    box-shadow: inset 0rem -0.0625rem 0rem #b5b5b5,inset -0.0625rem 0rem 0rem #e3e3e3,inset 0.0625rem 0rem 0rem #e3e3e3,inset 0rem 0.0625rem 0rem #e3e3e3;
    padding: 0.375rem 0.75rem;
}
.block-add-products{
    width: 100%;
    height: 250px;
    border-radius: 8px;
    cursor: pointer;
}
.block-add-products:hover{
background: #f9f9f9;
}
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #ffa201;
  background-color: #ffa201 !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem #ffa201;
}
.leadTable >>> thead {
  border-radius: 6px;
  color: black;
  background: rgba(179, 175, 182, 0.07);
  border: none;
}
.leadTable >>> thead tr {
  border-radius: 10px;
}
.leadTable >>> thead th {
  border: none;
}
.loader {
  min-height: 40px;
}
.product-image {
  height: 40px;
  width: 40px;
  /* border: 4px solid rgb(255, 255, 255); */
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  background-size: cover;
  /* padding: 0.25rem; */
  /* background-color: #fff; */
}
.customPagination >>> li > button {
  color: #4d1b7e;
}

.customPagination >>> li.active > button {
  color: white;
  background-color: #4d1b7e !important;
  border-color: #4d1b7e;
}
.customPagination >>> li > button:hover {
  color: white;
  background-color: #6b4690 !important;
  border-color: #4d1b7e;
}
#product-detail >>> button {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
}
.search-text:focus-visible {
  outline: none;
}
.search-text:active + .fa-search {
  color: #4d1b7e;
}
.tag {
  background: #bfbfc0;
  border-radius: 20px;
  border: 1px dashed;
  font-size: 14px;
}
.icon {
  color: #949495;
  transition: 0.5s ease all;
}
.icon:hover {
  background: rgb(77 27 126 / 20%);
  color: #4d1b7e;
}
.filter-active {
  background: rgb(77 27 126 / 20%);
  color: #4d1b7e;
}
.search {
  border-radius: 6px;
  border: 0.8px solid #b3afb6;
}
.search:focus-within {
  color: #495057;
  background-color: #fff;
  /* border-color: #4d1b7e; */
  outline: none;
  box-shadow: 0 0 0 0.1rem rgba(77, 27, 126, 0.25);
}
.cursor-pointer {
  cursor: pointer;
}
#product-detail >>> button::after {
  display: none;
}
#product-detail >>> ul {
  background: #ffffff;
  box-shadow: 0px 10px 20px -3px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
#product-detail >>> li.topbarLink {
  color: #344054;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
}
#product-detail >>> li a:active {
  color: #fff;
  text-decoration: none;
  background-color: #4d1b7e;
}
/* .filter-controls::after{
    content:'';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #edebeb;
  } */
.sync-filtered:hover {
  color: #4d1b7e !important;
}
.product-stripe {
  position: relative;
  top: 38px;
  background: #f4f3f4;
  display: flex;
  align-items: center;
  top: 48px;
}
.product-stripe strong {
  color: #4d1b7e;
  cursor: pointer;
}
.product-stripe strong:hover {
  text-decoration: underline;
}
</style>